import '../styles/Landingpage.css'
import { useEffect, useRef, useState } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'; 

function Landingpage()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [LandingpageTop, setLandingpageTop] = useState('LandingpageTop');
    const [LandingpageMiddle, setLandingpageMiddle] = useState('LandingpageMiddle');
    const [LandingpageBottom, setLandingpageBottom] = useState('LandingpageBottom');
    const [LandingpageMiddePictureArea, setLandingpageMiddePictureArea] = useState('LandingpageMiddePictureArea');
    const [LandingpageMiddleTitelArea, setLandingpageMiddleTitelArea] = useState('LandingpageMiddleTitelArea');
    const [LandingPageAIPic, setLandingPageAIPic] = useState('LandingPageAIPic');
    const [LandingPageBottomBox, setLandingPageBottomBox] = useState('LandingPageBottomBox');
    const [LandingPageTitelField, setLandingPageTitelField] = useState('LandingPageTitelField');
    const [LandingPageTitelRow1, setLandingPageTitelRow1] = useState('LandingPageTitelRow1');
    const [LandingPageTitelRow2, setLandingPageTitelRow2] = useState('LandingPageTitelRow2');
    const [TitelElement, setTitelElement] = useState('TitelElement');
    const [TitelElementColored, setTitelElementColored] = useState('TitelElement_Colored');
    const [LandingBoxTitel, setLandingBoxTitel] = useState('LandingBoxTitel');
    const [LandindBoxSubtitel, setLandindBoxSubtitel] = useState('LandindBoxSubtitel');
    const [LinkDestin, setLinkDestin] = useState('/Modul1_Info')

    useEffect(()=>{

        if(windowSize.current[0] > 2000)
        {
            setLandingBoxTitel('LandingBoxTitel_4K');
            setLandindBoxSubtitel('LandindBoxSubtitel_4K');
        }

        if(windowSize.current[0] < 1200)
        {
            setLandingpageTop('LandingpageTop_Ipad');
            setLandingpageMiddle('LandingpageMiddle_Ipad')
            setLandingpageBottom('LandingpageBottom_Ipad');
            setLandingPageAIPic('LandingPageAIPic_Ipad');
            setLandingPageBottomBox('LandingPageBottomBox_Ipad');
            setLandingPageTitelField('LandingPageTitelField_Ipad');
            setLandingPageTitelRow1('LandingPageTitelRow1_Ipad');
            setLandingPageTitelRow2('LandingPageTitelRow2_Ipad');
            setTitelElement('TitelElement_Ipad');
            setTitelElementColored('TitelElement_Colored_Ipad');
            setLandingBoxTitel('LandingBoxTitel_Ipad');
            setLandindBoxSubtitel('LandindBoxSubtitel_Ipad');

        }

        if(windowSize.current[0] < 900)
            {
                setLandingPageTitelField('LandingPageTitelField_Ipad_upright');
                setLandingPageAIPic('LandingPageAIPic_Ipad_upright');
                setLandingPageTitelRow1('LandingPageTitelRow1_Ipad_upright');
                setTitelElementColored('TitelElement_Colored_Ipad_upright');
                setLandingPageTitelRow2('LandingPageTitelRow2_Ipad_upright');
                setTitelElement('TitelElement_Ipad_upright');
                setLandingBoxTitel('LandingBoxTitel_Ipad_upright');
                setLandindBoxSubtitel('LandindBoxSubtitel_Ipad_upright');

                
            }

        if(windowSize.current[0] < 600)
        {
            setLandingpageTop('LandingpageTop_Mobile');
            setLandingpageMiddle('LandingpageMiddle_Mobile');
            setLandingpageBottom('LandingpageBottom_Mobile');
            setLandingPageAIPic('LandingPageAIPic_Mobile');
            setLandingPageBottomBox('LandingPageBottomBox_Mobile');
            setLandingPageTitelField('LandingPageTitelField_Mobile');
            setLandingPageTitelRow1('LandingPageTitelRow1_Mobile');
            setLandingPageTitelRow2('LandingPageTitelRow2_Mobile');
            setTitelElement('TitelElement_Mobile');
            setTitelElementColored('TitelElement_Colored_Mobile');
            setLandingBoxTitel('LandingBoxTitel_Mobile');
            setLandindBoxSubtitel('LandindBoxSubtitel_Mobile');
        }

    }, windowSize.current[0]);

    useEffect(()=>{
        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
        {
            setLinkDestin('/Modul3')
        }
    },[parseInt(Cookies.get("SchreibenmitKI_UserCode"))])

    return(
        <div className='LandingpageWrapper'>
            <Navigation Page={"Landingpage"}/>
            <div className='LandingpageBody'>
                <div className={LandingpageTop}></div>
                <div className={LandingpageMiddle}>
                    <div className={LandingpageMiddePictureArea}>
                        <div className={LandingPageAIPic}></div>
                    </div>
                    <div className={LandingpageMiddleTitelArea}>
                        <div className={LandingPageTitelField}>
                            <div className={LandingPageTitelRow1}>
                                <div className={TitelElementColored}>Schreiben</div>
                                <div className={TitelElement}>mit KI</div>
                            </div>
                            <div className={LandingPageTitelRow2}>
                                <div className={TitelElement}>im Lehramt</div>
                            </div>
                        </div>
                        <Link to={LinkDestin} style={{textDecoration:"none"}}><Button MarginLeft="15" Text="Direkt zum Workshop"></Button></Link>
                    </div>
                </div>
                <div className={LandingpageBottom}>
                    <div className={LandingPageBottomBox}>
                        <div className={LandingBoxTitel}>Nächster Workshop:</div>
                        <div className={LandindBoxSubtitel}> <i>Donnerstag 17.04.2025 und 24.04.2025, jeweils 9:00 - 13:00 Uhr</i></div>
                        
                        <div className={LandindBoxSubtitel}> Das Schreiben anleiten: Schreibaufgaben mit KI entwickeln und reflektieren</div>
                    </div>
                </div>
            </div>
            <Footer Page={"Landingpage"}/>
        </div>
    )
}

export default Landingpage;